@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
    --primary-color: #000;
    --secondary-color: #def
}

.shake-content {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate(0, 0);
    backface-visibility: hidden;
    background-color: #a10202 !important;
    transition: background-color .4s;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate(-1px, 0);
    }

    20%, 80% {
        transform: translate(2px, 0);
    }

    30%, 50%, 70% {
        transform: translate(-4px, 0);
    }

    40%, 60% {
        transform: translate(4px, 0);
    }
}

.banner {
    height: 40px;
    /*clip-path: polygon(0% 0%,100% 0%,100% 69%,50% 100%,0% 69%);*/
}
.banner-div::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*clip-path: polygon(0% 0%,100% 0%,100% 69%,50% 100%,0% 69%);*/
    background: linear-gradient(45deg, rgba(0,0,0,.25) 0%, rgba(255,255,255,.25) 15%, rgba(0,0,0,.25) 30%, rgba(255,255,255,.25) 45%, rgba(0,0,0,.25) 60%, rgba(255,255,255,.25) 75%, rgba(0,0,0,.25) 90%);
}

.badge {
    place-self: center;
    width: fit-content;
    padding-top: 0.1em;
    padding-bottom: 0.1rem;
}

.material-input {
    background-color: #dbd9d9;
}
.material-input div {
    margin-right: 0;

}
.material-input input{
    background-color: white;
}
.material-input > input:focus {
    --tw-ring-shadow: unset;
}


.confirm-modal-close {
    width: 80%;
    left: 10%;
    border-radius: 5px;
    background-color: #1f1f1f;
    color: white;
    box-shadow: 0 0 1em black;
    z-index: 99;
    font-size: 0.8em;
    padding: 1em;
}

.hover-underline:hover {
    text-decoration: underline;
}

.preview {
    flex: 1 0 42%;
    width: fit-content;
}

.push-preview {
    width: 380px;
    margin: 1em 1em 0;
}
.push-preview .placeholder {
    max-width: 20%;
    object-fit: cover;
}
.push-preview div {
    max-width: 66.2%;
    width: 66.2%;
}
.push-windows-image {
    margin: 0 1em;
    width: calc(100% - 32px);
}
.push-windows-image img {
    width: 100%;
    max-height: 145px;
    object-fit: cover;
}

.thumb-failsafe {
    text-transform: uppercase;
    border-radius: 5px;
    position: absolute;
    top: 15%;
    right: 5em;
    display: flex;
    height: 70%;
    max-height: 82px;
    width: 145px;
    align-items: center;
    justify-content: center;
    color: #4c545f;
    background-color: #e5e7eb;
}
.list-thumb {
    border-radius: 5px;
    position: absolute;
    height: 70%;
    max-height: 82px;
    width: 145px;
    top: 15%;
    right: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.list-thumb img{
    border-radius: 5px;
    max-width: 135%;
    min-height: 100%;
}

.notification-summary {
    margin: 0.8em 0 !important;
    background: #e9e9e9;
    width: fit-content;
    padding: 0.2em 0.5em;
    border-radius: 5px
}

.notification-summary span {
    color: #ec4899;
    font-weight: bolder;
}